/* eslint react/no-danger: 0 */
/* eslint @atlaskit/ui-styling-standard/no-global-styles: 0 */
import { css, cssMap, keyframes } from '@compiled/react';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { fg } from '@confluence/feature-gating';
import type { ProgressBarContainer } from '@confluence/global-progress-bar-container';
import {
	DEFAULT_LOADING_BAR_COLOR,
	EXIT_ANIMATION_SPEED,
	PROGRESS_BAR_ID,
	PROGRESS_STYLE_ID,
} from '@confluence/global-progress-bar-container';

import { useProgressBarController } from './useProgressBarController';
import { useProgressBarListenerController } from './useProgressBarListenerController';

const newStyledProgressBarStyles = css({
	height: '2px',
	opacity: '0.6',
	position: 'absolute',
	transformOrigin: '0 0',
	width: '100%',
	willChange: 'transform',
	zIndex: '999',
});
type WrappedProgressBarProps = {
	highlightColor?: string;
	status: string;
	progressBar: ProgressBarContainer;
	duration: number;
	isNav4: boolean;
};

type StyledAttributes = {
	transform?: string;
	transitionDuration?: string;
	isDelayed?: boolean;
	animation?: string;
	ssrProgressBarLoadPercent?: number;
	isNav4?: boolean;
};

type StyledProgressBarAttributes = StyledAttributes & {
	background: string;
	style: any;
};

const ssrAnimation = keyframes({
	from: { width: '0' },
	to: { width: '100%' },
});

const shimmerAnimation = keyframes({
	from: { left: '-15%', width: '15%' },
	to: { left: '115%', width: '15%' },
});

const animationRules = cssMap({
	ssrOnly: { animation: ssrAnimation },
	ssrAndDelayed: { animation: `${ssrAnimation}, ${shimmerAnimation}` },
	delayed: { animation: shimmerAnimation },
});

const getStyledAttributes = (
	status,
	duration,
	ssrProgressBarDuration,
	ssrProgressBarLoadPercent,
) => {
	switch (status) {
		case 'START':
			return {
				transform: 'scaleX(0.99)',
				transitionDuration: `${duration}ms`,
			};

		case 'FINISH':
			return {
				transform: 'scaleX(1)',
				transitionDuration: `${EXIT_ANIMATION_SPEED}ms`,
			};

		case 'DELAYED':
			return {
				animation: 'shimmer 1200ms infinite',
				isDelayed: true,
				transform: 'scaleX(1)',
				transitionDuration: '0ms',
			};

		case 'SSR':
			// When browser is rendering the static HTML progress from 0 to 1/3
			// Then when SPA loads proceed from 33.33% to 99%, then 100% when apdex ends
			return {
				// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				animation: process.env.REACT_SSR ? `ssr ${ssrProgressBarDuration}ms ease-out` : 'none',
				transform: `scaleX(${ssrProgressBarLoadPercent / 100})`,
				transitionDuration: '0ms',
			};

		case 'RESET':
		default:
			return {
				animation: 'none',
				transform: `scaleX(0)`,
				transitionDuration: '0ms',
			};
	}
};

const StyledProgressBar: FC<StyledProgressBarAttributes> = ({ isDelayed, background, style }) => {
	return (
		<div
			style={{
				background,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				...style,
			}}
			data-vc="global-progress-bar-nav3"
			data-testid={PROGRESS_BAR_ID}
			css={[
				newStyledProgressBarStyles,
				// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				isDelayed && !process.env.REACT_SSR && animationRules['delayed'],
				// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				!isDelayed && process.env.REACT_SSR && animationRules['ssrOnly'],
				// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				isDelayed && process.env.REACT_SSR && animationRules['ssrAndDelayed'],
			]}
		/>
	);
};

//start Nav 4 styles
const generateLoadingBarStyles = ({
	animation = 'none',
	background,
	isDelayed,
	transform,
	isNav4,
	transitionDuration,
}: StyledAttributes & { background: string }): string => {
	return `
	body::before{
    content: '';
    display: block;
    animation: ${animation};
	  background: ${
			// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
			background
		};
    height: 2px;
    z-index: 999;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
	transform: ${transform};
	transition-duration: ${transitionDuration};
    transform-origin: 0 0;
    will-change: transform;
  }
  ${
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		process.env.REACT_SSR ? `@keyframes ssr { from { width: 0%; } to { width:100%; } }` : ''
	}
	${
		isDelayed
			? ` @keyframes shimmer { from { left: -15%; width: 15%; } to { left: 115%; width: 15%; } }`
			: ''
	}
	${
		isNav4
			? `opacity: '0.6',
	position: 'absolute',
	transformOrigin: '0 0',
	width: '100%',
	willChange: 'transform'`
			: ''
	}
	${
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		isNav4 && isDelayed && !process.env.REACT_SSR
			? `@keyframes shimmer {
			from: { left: '-15%', width: '15%' },
			to: { left: '115%', width: '15%' }
		}`
			: ''
	}
	${
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		isNav4 && !isDelayed && process.env.REACT_SSR
			? `@keyframes ssr {
			{
				from: { width: '0' },
				to: { width: '100%' },
			}
		}`
			: ''
	}
	${
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		isNav4 && isDelayed && process.env.REACT_SSR
			? `@keyframes ssr {
			from: { width: '0' },
			to: { width: '100%' },
		}
		@keyframes shimmer {
			from: { left: '-15%', width: '15%' },
			to: { left: '115%', width: '15%' },
		}`
			: ''
	}
`;
};
//end Nav 4 specific code

export const WrappedProgressBar: FC<WrappedProgressBarProps> = ({
	highlightColor = DEFAULT_LOADING_BAR_COLOR,
	status,
	duration,
	progressBar,
	isNav4,
}) => {
	const ssrAttributes = getStyledAttributes(
		'SSR',
		0,
		6000, // ssrProgressBarDuration
		80, // ssrProgressBarLoadPercent
	);

	// Initially, set progress bar to 0%, this ensures that progress bar does not start with 99% on the first load
	const initialAttributes = getStyledAttributes(
		'RESET',
		duration,
		6000, // ssrProgressBarDuration,
		80, // ssrProgressBarLoadPercent
	);

	const [attributes, setAttributes] = useState<StyledAttributes>(
		// If the page is currently rendering on the server-side or was rendered on server-side
		// We need to set the start point synchronously
		status === 'SSR' ? ssrAttributes : initialAttributes,
	);

	useProgressBarController(progressBar);
	useProgressBarListenerController(progressBar);
	/**
	 * this logic (useEffect -> setTimeout -> setState) is necessary to fix a
	 * rendering issue where the progress bar animation starts loading at 99%.
	 * this should be investigated more and solved in a future ticket.
	 */
	useEffect(() => {
		const timeout = setTimeout(() => {
			setAttributes(
				getStyledAttributes(
					status,
					duration,
					6000, // ssrProgressBarDuration,
					80, // ssrProgressBarLoadPercent
				),
			);
		}, 0);

		return () => clearTimeout(timeout);
	}, [duration, status]);

	const dynamicLoadingStyles = generateLoadingBarStyles({
		...attributes,
		background: highlightColor,
	});

	if (fg('confluence_frontend_dynamic_nav3_progress_bar')) {
		return (
			<style
				data-testid={PROGRESS_BAR_ID}
				dangerouslySetInnerHTML={{ __html: dynamicLoadingStyles }}
				data-vc={attributes.isNav4 ? '' : 'global-progress-bar-nav3'}
			/>
		);
	}

	return isNav4 ? (
		<style
			data-testId={PROGRESS_STYLE_ID}
			dangerouslySetInnerHTML={{ __html: dynamicLoadingStyles }}
		/>
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
		<StyledProgressBar isNav4={false} background={highlightColor} style={attributes} />
	);
};
