import { useIsTransition } from '@confluence/route-manager';

import { useLivePageSSRStatus, type LivePageSSRStatusType } from './useLivePageSSRStatus';

// Hook implementing the "shortcut" logic for determining the page type.
// On SSR or on post-SSR client hydration we know for certain if the page is live or not, so we don't need to query anything else.
export const useIsLivePageWithSSR = (): LivePageSSRStatusType => {
	const isTransition = useIsTransition();
	const ssrStatus = useLivePageSSRStatus();

	if (!ssrStatus.isTypeKnown && isTransition) {
		return {
			isLive: undefined,
			isTypeKnown: false,
		};
	}
	return ssrStatus;
};
