import { token } from '@atlaskit/tokens';

export const PROGRESS_STYLE_ID = 'progressStyles';
export const PROGRESS_BAR_ID: string = 'global-progress-bar';
export const DEFAULT_LOADING_BAR_COLOR: string = token('color.background.brand.bold');

// When the progress bar received a finish signal, move the progress bar to the end within 300ms.
export const EXIT_ANIMATION_SPEED: number = 300;

// ${DEFAULT_LOADING_DURATION} controls progress bar moving speed.
export const DEFAULT_LOADING_DURATION: number = 6000;

// If the page haven't loaded within 20s, reset and hide the progress bar.
export const TIMEOUT_TIMER: number = 20000;

// After progress bar moved to the end, stay idle for extra 50ms.
export const POST_FINISHED_IDLE_DURATION: number = EXIT_ANIMATION_SPEED + 50;
