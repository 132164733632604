import { useContext, useEffect } from 'react';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { hasBMPageLoadStarted, isBMPageLoad } from '@confluence/browser-metrics';
import { fg } from '@confluence/feature-gating';
import { getCachedPageData } from '@confluence/live-pages-utils/entry-points/getCachedPageData';
import { useIsLivePageWithSSR } from '@confluence/live-pages-utils/entry-points/useIsLivePageWithSSR';
import type { ProgressBarContainer } from '@confluence/global-progress-bar-container';

/**
 * "contentSlug" is changable due to url encode, content title contains special charactor, changed title, etc.
 * If "contentSlug" changed, URL will be redirected, but the page content won't rerender.
 * In this case, the progress bar should not show up.
 * To handle that, use "contentId" as screen's identity, instead of pathname.
 * Assuming if a URL contains "contentSlug" param, then it much contains "contentId" as well.
 * The above assumption is valid according to: next/packages/named-routes/src/index.ts
 *
 * Another circumstance that progress bar should not show up is, when blog post url changed from pattern VIEW_BLOG_DATE_LEGACY to VIEW_BLOG or conversely.
 */
function isPreviousPage(match, prevMatch) {
	if (
		match &&
		match.params &&
		match.name &&
		prevMatch &&
		prevMatch.params &&
		prevMatch.name &&
		(match.name === prevMatch.name ||
			(match.name.startsWith('VIEW_BLOG') &&
				prevMatch.name.startsWith('VIEW_BLOG') &&
				'contentSlug' in match.params &&
				'contentSlug' in prevMatch.params) ||
			(match.name === 'HOME' && prevMatch.name === 'WIKI'))
	) {
		return prevMatch.params.contentId === match.params.contentId;
	}
	return false;
}

export const useProgressBarController = (progressBar: ProgressBarContainer) => {
	const { match } = useContext(RoutesContext);
	const livePageSSRStatus = useIsLivePageWithSSR();

	useEffect(() => {
		const shouldHideProgressBar = () => {
			const conditions = [
				() => {
					// Hide the progress bar for all live pages.
					if (!fg('confluence_perceived_perf_hide_loading_bar')) return false;

					// Check for SSR'd live page. If it is, hide the progress bar.
					if (livePageSSRStatus?.isTypeKnown && livePageSSRStatus?.isLive) return true;

					// Check for non-SSR'd live data from live pages fast loading. If it is, hide the progress bar.
					const { subType, isArchived } = getCachedPageData(match?.params.contentId);
					const isLivePage = subType === 'live' && isArchived === false;
					return isLivePage && match?.name === 'VIEW_PAGE';
				},
			];
			return conditions.some((condition) => condition());
		};

		if (!isPreviousPage(match, progressBar.prevMatch)) {
			// These are special conditions where we hide the progress bar for perceived performance experiments.
			if (shouldHideProgressBar()) {
				void progressBar.reset();
			} else if (isBMPageLoad(match) && hasBMPageLoadStarted()) {
				void progressBar.start();
			} else {
				void progressBar.reset();
			}
		}

		progressBar.prevMatch = match;

		return () => {
			void progressBar.reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match && match.pathname]);
};
