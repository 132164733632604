// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { Match } from '@confluence/route-manager';
import { getSSRRenderInputs } from '@confluence/ssr-utilities';
import { fg } from '@confluence/feature-gating';

import {
	DEFAULT_LOADING_DURATION,
	TIMEOUT_TIMER,
	POST_FINISHED_IDLE_DURATION,
} from './defaultVariables';

/**
 * RESET: 0% progress. This is the initial status, and the end status.
 * START: Progress bar moves from 0% to 99%. Within ${DEFAULT_LOADING_DURATION}ms.
 * FINISH: Progress bar moves to 100%, within ${EXIT_ANIMATION_SPEED}ms.
 * DELAYED: If the page haven't finished loading within ${DEFAULT_LOADING_DURATION}ms, the progress bar will be under DELAYED status.
 *          If the page haven't finished loading within ${TIMEOUT_TIMER}ms, the progress bar will be under RESET status.
 */
export type ProgressBarStatus = 'RESET' | 'START' | 'FINISH' | 'DELAYED' | 'SSR';

type ProgressBarContainerStateType = {
	status: ProgressBarStatus;
	duration?: number;
};

/**
 * Reference from: next/packages/quick-reload/src/SmartTimer.ts
 * Our TypeScript configuration currently has it validating against Node.js
 * types, not browser ones, and they have different return types for
 * setTimeout. This is the browser's type:
 */
declare function setTimeout(callback: (...args: any[]) => void, ms: number, ...args: any[]): number;

export class ProgressBarContainer extends Container<ProgressBarContainerStateType> {
	constructor(state?: ProgressBarContainerStateType) {
		super();
		this.state = {
			status: this.getInitialStatus(state),
			duration: (state && state.duration) || DEFAULT_LOADING_DURATION,
		};
	}
	private getInitialStatus(state?: ProgressBarContainerStateType): ProgressBarStatus {
		if (state) return state.status;

		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		const isSSR = process.env.REACT_SSR || window.__SSR_RENDERED__;

		if (isSSR && fg('confluence_perceived_perf_hide_loading_bar')) {
			// window.__SSR_IS_LIVE_PAGE__ only works in hydration after SSR, so need to use ssr-inputs when its in SSR mode to get the live page data.
			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
			const isLivePageSSR = process.env.REACT_SSR
				? getSSRRenderInputs().isLivePage
				: window.__SSR_IS_LIVE_PAGE__;

			if (isLivePageSSR) return 'RESET';
		}

		return isSSR ? 'SSR' : 'RESET';
	}

	private exitAnimationTimer: number | undefined;
	private delayedAnimationTimer: number | undefined;
	private timeoutTimer: number | undefined;

	/**
	 * There are race conditions where we try to start too soon after resetting,
	 * with the result being that state is not yet updated and the start fails.
	 * This class property is a hack used to circumvent that behavior.
	 */
	private recentlyReset: boolean = false;
	/**
	 * isStarting is used for handling a race condition.
	 * When a page is cached, start() and finish() are fired simultaneously.
	 * In start() function, setState({ status: "START" }) is async,
	 * so when finish() is invoked, status haven't been set to "START" yet.
	 */
	private isStarting: boolean = false;
	private isStopping: boolean = false;

	prevMatch: Match | null = null;

	isRunning = () => (this.state.status === 'START' || this.isStarting) && !this.isStopping;

	isDelayed = () => this.state.status === 'DELAYED';

	hasStarted = () => this.isRunning() || this.isDelayed();

	isStartable = () =>
		this.state.status === 'RESET' || this.state.status === 'SSR' || this.recentlyReset;

	getStatus = () => {
		return this.state.status;
	};

	getDuration = () => {
		return this.state.duration || DEFAULT_LOADING_DURATION;
	};

	/**
	 * The progress bar start to move.
	 */
	start = (duration = DEFAULT_LOADING_DURATION, timeoutDuration = TIMEOUT_TIMER) => {
		if (!this.isStartable()) {
			return Promise.resolve();
		}

		this.recentlyReset = false;
		this.isStarting = true;

		/**
		 * If the progress bar has started, and haven't received a finish signal within ${DEFAULT_LOADING_DURATION}ms,
		 * render shimmer and linear-gradient effect.
		 */
		this.delayedAnimationTimer = setTimeout(() => {
			if (!this.isRunning()) return;
			void this.setState({
				status: 'DELAYED',
			});
		}, duration);

		/**
		 * If the progress bar has started, and haven't received a finish signal within ${TIMEOUT_TIMER}ms,
		 * hide the progress bar, and reset the data.
		 */
		this.timeoutTimer = setTimeout(() => {
			if (!this.isDelayed()) return;
			void this.reset();
		}, timeoutDuration);

		return this.setState(
			{
				status: 'START',
				duration,
			},
			() => {
				this.isStarting = false;
			},
		);
	};

	/**
	 * Trigger the progress bar to move to the end quickly.
	 */
	finish = () => {
		if (!(this.isRunning() || this.isDelayed())) {
			return;
		}
		this.isStopping = true;

		let promise: Promise<void>;
		if (this.isDelayed()) {
			promise = this.reset();
		} else {
			if (fg('confluence_progress-bar-bugfix')) {
				this.isStarting = false;
			}
			promise = this.setState(
				{
					status: 'FINISH',
				},
				() => {
					this.isStopping = false;
				},
			);
		}

		this.exitAnimationTimer = setTimeout(() => {
			void this.reset();
		}, POST_FINISHED_IDLE_DURATION);
		return promise;
	};

	/**
	 * Hide the progress bar and reset the data.
	 */
	reset = () => {
		clearTimeout(this.exitAnimationTimer);
		clearTimeout(this.delayedAnimationTimer);
		clearTimeout(this.timeoutTimer);

		const promise = this.setState({
			status: 'RESET',
		});
		this.recentlyReset = true;
		this.isStopping = false;
		return promise;
	};
}
